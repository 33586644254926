import { useApi } from '@hooks/useApi';
import { Text } from '@ui/Text';
import { useDesktopHeaderStore } from '@stores/desktopHeaderStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@ui/Button';
import { cn } from '../../../../../utils';
import { BatteryStatus } from '@components/BatteryStatus';
import { Table } from '@ui/Table';
import { Input } from '@ui/Input';
import { ContainerDetailsMap } from './ContainerDetailsMap';
import { IconButton } from '@ui/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import illuNoData from '@assets/icons/illu-noData.svg';
import { notify } from '../../../../../notify';
import ContainerDetailsTemperatureGraph from './ContainerDetailsTemperatureGraph';

export function ContainerDetails() {
  const api = useApi();
  const queryClient = useQueryClient();
  const desktopHeaderStore = useDesktopHeaderStore();
  const { containerId } = useParams<{ containerId: string }>();

  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [selectedHistoryEntryId, setSelectedHistoryEntryId] = useState<string | undefined>();
  const [hoveredHistoryEntryId, setHoveredHistoryEntryId] = useState<string | undefined>();

  if (!containerId) {
    return <Navigate to="/desktop/data/containers" />;
  }

  const getContainerHistoryCSVMutation = useMutation({
    mutationFn: api.containers.getContainerHistoryCSV,
  });

  const updateContainerMutation = useMutation({
    mutationFn: api.containers.updateContainer,
  });

  function handleCSVExport() {
    getContainerHistoryCSVMutation.mutate(
      { containerId: containerId! },
      {
        onSuccess: (res) => {
          if (!res.ok) {
            return;
          }

          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          a.setAttribute('download', `SupplyLine_Container_History_${container!.serial}.csv`);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
      }
    );
  }

  function handleMarkAllRead() {
    updateContainerMutation.mutate(
      { containerId: containerId!, data: { warningsAcknowledgedAt: new Date() } },
      {
        onSuccess: async (res) => {
          if (!res.ok) {
            return;
          }

          await queryClient.invalidateQueries(['containers', containerId]);
          notify({ type: 'success', message: 'Warnungen als gelesen markiert.' });
        },
      }
    );
  }

  const containerQuery = useQuery({
    queryKey: ['containers', containerId],
    queryFn: () => api.containers.getContainerById({ containerId: containerId }),
  });

  useEffect(() => {
    desktopHeaderStore.setHeader({ title: 'Container Details' });
  }, []);

  if (containerQuery.isLoading) {
    return 'Loading...'; // TODO better loading | 30.01.2024 jl
  }

  const container = containerQuery.data?.ok ? containerQuery.data.data : null;

  if (!container) {
    return <Navigate to="/desktop/data/containers" />;
  }

  let historyEntries = container.history;
  if (filterFrom) {
    const filterFromDate = new Date(`${filterFrom}T00:00:00Z`);
    historyEntries = historyEntries.filter((x) => x.recordedAt.getTime() >= filterFromDate.getTime());
  }
  if (filterTo) {
    const filterToDate = new Date(`${filterTo}T23:59:59.999Z`);
    historyEntries = historyEntries.filter((x) => x.recordedAt.getTime() <= filterToDate.getTime());
  }

  const selectedHistoryEntry = historyEntries.find((x) => x.id === selectedHistoryEntryId) || historyEntries[0];

  return (
    <div className="pt-10">
      <div className="grid h-[calc(100vh-114px)] grid-cols-1 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1">
        <div className={`${historyEntries.length > 0 ? 'row-span-1 lg:col-span-2' : 'row-span-2 lg:col-span-3'}`}>
          <div className="row-span-1 mb-5 flex h-full flex-col bg-white shadow-md">
            <div className="flex items-center justify-between gap-2 p-3 sm:px-6 sm:py-3">
              <div className="flex items-center gap-2">
                <Link to="/desktop/data/containers">
                  <IconButton>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Link>
                <h2>
                  <Text variant="heading2">{container.serial}</Text>
                </h2>
              </div>
              <Button
                variant="text"
                LeadingIcon={<FileDownloadIcon />}
                disabled={getContainerHistoryCSVMutation.isLoading}
                onClick={handleCSVExport}
              >
                Export
              </Button>
            </div>

            <div className="flex items-center justify-between gap-4 px-3 pb-3 sm:px-6 sm:pb-10">
              <div className="flex flex-wrap gap-5">
                <div>
                  <label htmlFor="filterFromDate">
                    <Text variant="heading3" className="text-sm">
                      Datum von
                    </Text>
                  </label>
                  <Input
                    id="filterFromDate"
                    variant="contained"
                    label="Datum von"
                    type="date"
                    value={filterFrom}
                    onChange={(e) => setFilterFrom(e.target.value)}
                    className="w-80"
                  />
                </div>

                <div>
                  <label htmlFor="filterToDate">
                    <Text variant="heading3" className="text-sm">
                      Datum bis
                    </Text>
                  </label>
                  <Input
                    id="filterToDate"
                    variant="contained"
                    label="Datum bis"
                    type="date"
                    className="w-80"
                    value={filterTo}
                    onChange={(e) => setFilterTo(e.target.value)}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <Text variant="heading3">Filter</Text>

                  <div className="flex gap-1">
                    <button
                      onClick={() => {
                        const oneMonthAgo = new Date();
                        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

                        const year = oneMonthAgo.getFullYear();
                        const month = (oneMonthAgo.getMonth() + 1).toString().padStart(2, '0');
                        const day = oneMonthAgo.getDate().toString().padStart(2, '0');

                        setFilterFrom(`${year}-${month}-${day}`);
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      1 M
                    </button>
                    <button
                      onClick={() => {
                        const threeMonthsAgo = new Date();
                        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

                        const year = threeMonthsAgo.getFullYear();
                        const month = (threeMonthsAgo.getMonth() + 1).toString().padStart(2, '0');
                        const day = threeMonthsAgo.getDate().toString().padStart(2, '0');

                        setFilterFrom(`${year}-${month}-${day}`);
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      3 M
                    </button>
                    <button
                      onClick={() => {
                        const sixMonthsAgo = new Date();
                        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

                        const year = sixMonthsAgo.getFullYear();
                        const month = (sixMonthsAgo.getMonth() + 1).toString().padStart(2, '0');
                        const day = sixMonthsAgo.getDate().toString().padStart(2, '0');

                        setFilterFrom(`${year}-${month}-${day}`);
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      6 M
                    </button>
                    <button
                      onClick={() => {
                        const nineMonthsAgo = new Date();
                        nineMonthsAgo.setMonth(nineMonthsAgo.getMonth() - 9);

                        const year = nineMonthsAgo.getFullYear();
                        const month = (nineMonthsAgo.getMonth() + 1).toString().padStart(2, '0');
                        const day = nineMonthsAgo.getDate().toString().padStart(2, '0');

                        setFilterFrom(`${year}-${month}-${day}`);
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      9 M
                    </button>
                    <button
                      onClick={() => {
                        const twelveMonthsAgo = new Date();
                        twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

                        const year = twelveMonthsAgo.getFullYear();
                        const month = (twelveMonthsAgo.getMonth() + 1).toString().padStart(2, '0');
                        const day = twelveMonthsAgo.getDate().toString().padStart(2, '0');

                        setFilterFrom(`${year}-${month}-${day}`);
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      12 M
                    </button>
                    <button
                      onClick={() => {
                        setFilterFrom('');
                        setFilterTo('');
                      }}
                      className="rounded-md bg-gray-50 px-2 py-0.5 text-xs uppercase text-onSurface-highEmphasis transition-colors hover:bg-gray-100"
                    >
                      alle
                    </button>
                  </div>
                </div>
              </div>

              {historyEntries.length !== 0 && (
                <div>
                  <Button variant="outlined" disabled={updateContainerMutation.isLoading} onClick={handleMarkAllRead}>
                    alle gelesen
                  </Button>
                </div>
              )}
            </div>

            <div className="h-full overflow-x-auto overflow-y-auto border-t border-gray-100 pb-6">
              <Table>
                <Table.Header className="z-10">
                  <Table.Row noHover>
                    <Table.Head>Ereigniszeit</Table.Head>
                    <Table.Head>Alter der Meldung</Table.Head>
                    <Table.Head>Standort</Table.Head>
                    <Table.Head>Status</Table.Head>
                    <Table.Head className="text-right">Temperatur</Table.Head>
                    <Table.Head className="text-right">Schock</Table.Head>
                    <Table.Head className="text-right">Akkustand</Table.Head>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {historyEntries.map((historyEntry) => {
                    return (
                      <Table.Row
                        key={historyEntry.id}
                        role="button"
                        onClick={() => setSelectedHistoryEntryId(historyEntry.id)}
                        selected={historyEntry.id === selectedHistoryEntry?.id}
                        onMouseEnter={() => setHoveredHistoryEntryId(historyEntry.id)}
                        onMouseLeave={() => setHoveredHistoryEntryId(undefined)}
                      >
                        <Table.Cell className="font-mono">
                          <Text>{prettifyDateTime(historyEntry.createdAt)}</Text>
                        </Table.Cell>

                        <Table.Cell className="font-mono">
                          <Text>{prettifyTimeDifference(Date.now() - historyEntry.recordedAt.getTime())}</Text>
                        </Table.Cell>

                        <Table.Cell>
                          {historyEntry.location && (
                            <Text>{historyEntry.zone?.name || prettifyLocation(historyEntry.location)}</Text>
                          )}
                        </Table.Cell>

                        <Table.Cell className="font-mono">
                          <span
                            className={cn(
                              'inline-block w-full rounded-sm px-2.5 text-center',
                              historyEntry.isFilled && 'bg-[#CCF1D6]',
                              !historyEntry.isFilled && 'bg-gray-100'
                            )}
                          >
                            <Text>{historyEntry.isFilled ? 'voll' : 'leer'}</Text>
                          </span>
                        </Table.Cell>

                        <Table.Cell className="text-right font-mono">
                          {historyEntry.temperature && (
                            <span
                              className={cn(
                                'relative block w-full rounded-sm px-2.5 text-right',
                                historyEntry.temperature &&
                                  isTempOutOfRange(historyEntry.temperature.temperature) &&
                                  'bg-[#D54F4940] text-communication-error'
                              )}
                            >
                              {historyEntry.temperature &&
                                isTempOutOfRange(historyEntry.temperature.temperature) &&
                                historyEntry.createdAt.getTime() > container.warningsAcknowledgedAt.getTime() && (
                                  <span className="absolute right-0 top-0 h-2 w-2 -translate-y-1/3 translate-x-1/3 rounded-full bg-communication-error"></span>
                                )}
                              <Text>{historyEntry.temperature.temperature.toFixed(1)}°C</Text>
                            </span>
                          )}
                        </Table.Cell>

                        <Table.Cell className="text-right font-mono">
                          {historyEntry.shock && (
                            <span
                              className={cn(
                                'relative block w-full rounded-sm px-2.5 text-right',
                                historyEntry.shock && 'bg-[#D54F4940] text-communication-error'
                              )}
                            >
                              {historyEntry.shock &&
                                historyEntry.createdAt.getTime() > container.warningsAcknowledgedAt.getTime() && (
                                  <span className="absolute right-0 top-0 h-2 w-2 -translate-y-1/3 translate-x-1/3 rounded-full bg-communication-error"></span>
                                )}

                              {historyEntry.shock.intensity && (
                                <Text>
                                  {(historyEntry.shock.intensity / 1000).toLocaleString('de-DE', {
                                    maximumFractionDigits: 1,
                                  })}
                                  G
                                </Text>
                              )}
                            </span>
                          )}
                        </Table.Cell>

                        <Table.Cell className="font-mono">
                          <span className="flex items-center justify-end gap-2">
                            <Text>{historyEntry.batteryLevel}%</Text>
                            <BatteryStatus batteryLevel={historyEntry.batteryLevel} />
                          </span>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>

              {historyEntries.length === 0 && (
                <div className="flex w-full justify-center p-6">
                  <div className="grid justify-items-center py-[10%] text-center">
                    <img src={illuNoData} alt="No Data" />
                    <Text variant="heading3" className="pt-8">
                      Keine Daten verfügbar
                    </Text>
                    {container.history.length === 0 ? (
                      <Text variant="subtitle2" className="mt-1 max-w-sm text-sm">
                        Sobald eine verbaute SupplyLine-Cap Daten übermittelt, werden diese hier angezeigt.
                      </Text>
                    ) : (
                      <Text variant="subtitle2" className="mt-1 max-w-sm text-sm">
                        Für den ausgewählten Zeitraum gibt es keine Meldungen.
                      </Text>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {historyEntries.length > 0 && (
          <div className="col-span-1 row-span-2 grid h-full w-full grid-rows-3 lg:col-span-1 lg:row-span-1">
            <ContainerDetailsMap
              container={container}
              historyEntries={historyEntries}
              selectedHistoryEntryId={selectedHistoryEntry?.id}
              hoveredHistoryEntryId={hoveredHistoryEntryId}
              filterFrom={filterFrom}
              filterTo={filterTo}
              className={'row-span-2'}
            />

            <div className="row-span-1">
              <ContainerDetailsTemperatureGraph historyEntries={historyEntries} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function prettifyDateTime(date: Date): string {
  const dateStr = date.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${dateStr} Uhr`;
}

function prettifyLocation({ countryCode, address }: { countryCode: string | null; address: string | null }): string {
  const parts: string[] = [];

  if (countryCode) {
    parts.push(`${countryCode.toUpperCase()}`);
  }

  if (address) {
    parts.push(address);
  } else {
    parts.push('Unbekannte Adresse');
  }

  return parts.join(' - ');
}

function prettifyTimeDifference(ms: number): string {
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  return `${days} T, ${hours.toString().padStart(2, '0')} Std`;
}

function isTempOutOfRange(temp: number): boolean {
  return temp < 15 || temp > 35;
}
