import { zodResolver } from '@hookform/resolvers/zod';
import { useApi } from '@hooks/useApi';
import Grid from '@mui/material/Grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Text } from '@ui/Text';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { notify } from '../../../../notify';
import { DataWrapper } from '@ui/DataWrapper';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';

const formSchema = z.object({
  measurementsPerDay: z.coerce.number().optional(),
  gpsInterval: z.coerce.number().optional(),
  accumulateEvents: z.coerce.number().optional(),
  temperatureUpperBound: z.coerce.number().optional(),
  temperatureLowerBound: z.coerce.number().optional(),
  gpsDuration: z.coerce.number().optional(),
  logMask: z.coerce.number().optional(),
});

type Props = {
  rfidCarrier: {
    id: string;
    serial: string;
    rfidTagUID: string;
    imei: string | null;
    config: {
      measurementsPerDay?: number;
      gpsInterval?: number;
      accumulateEvents?: number;
      temperatureUpperBound?: number;
      temperatureLowerBound?: number;
      gpsDuration?: number;
      logMask?: number;
    };
  };
  onCancel: () => void;
};

export function SensorDetailsConfigTab({ rfidCarrier, onCancel }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const updateRfidCarrierConfigMutation = useMutation({
    mutationFn: api.rfidCarriers.updateRfidCarrierConfig,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['rfidCarriers'] }),
  });

  const handleSubmit = form.handleSubmit((data) => {
    updateRfidCarrierConfigMutation.mutate(
      {
        id: rfidCarrier.id,
        data: {
          measurementsPerDay: data.measurementsPerDay,
          gpsInterval: data.gpsInterval,
          accumulateEvents: data.accumulateEvents,
          temperatureUpperBound: data.temperatureUpperBound,
          temperatureLowerBound: data.temperatureLowerBound,
          gpsDuration: data.gpsDuration,
          logMask: data.logMask,
        },
      },
      {
        onSuccess: async (res) => {
          if (!res.ok) {
            switch (res.data.errCode) {
              case 'rfid_carrier_not_found': {
                return;
              }
              default: {
                const _exhaustiveCheck: never = res.data.errCode;
                return;
              }
            }
          }

          notify({ type: 'success', message: 'Config gespeichert' });
        },
      }
    );
  });

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <Text variant="heading2">Sensor Informationen</Text>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DataWrapper label="Seriennummer" value={rfidCarrier.serial} />
          </Grid>

          <Grid item xs={12} md={6}>
            <DataWrapper label="RFID Tag UID" value={rfidCarrier.rfidTagUID} />
          </Grid>

          <Grid item xs={12} md={6}>
            <DataWrapper label="IMEI" value={rfidCarrier.imei || '-'} />
          </Grid>
        </Grid>
      </div>

      <div className="flex flex-col gap-4">
        <Text variant="heading2">Konfiguration</Text>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <label htmlFor="measurementsPerDay">
              <Text variant="heading3" className="text-sm">
                Measurements per day
              </Text>
            </label>
            <Input
              id="measurementsPerDay"
              variant="contained"
              label="Measurements per day"
              type="number"
              defaultValue={rfidCarrier.config.measurementsPerDay}
              required
              {...form.register('measurementsPerDay')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="gpsInterval">
              <Text variant="heading3" className="text-sm">
                GPS Interval
              </Text>
            </label>
            <Input
              id="gpsInterval"
              variant="contained"
              label="GPS Interval"
              type="number"
              defaultValue={rfidCarrier.config.gpsInterval}
              required
              {...form.register('gpsInterval')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="accumulateEvents">
              <Text variant="heading3" className="text-sm">
                Accumulate Events
              </Text>
            </label>
            <Input
              id="accumulateEvents"
              variant="contained"
              label="Accumulate Events"
              type="number"
              defaultValue={rfidCarrier.config.accumulateEvents}
              required
              {...form.register('accumulateEvents')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="temperatureUpperBound">
              <Text variant="heading3" className="text-sm">
                Temperature upper bound
              </Text>
            </label>
            <Input
              id="temperatureUpperBound"
              variant="contained"
              label="Temperature upper bound"
              type="number"
              defaultValue={rfidCarrier.config.temperatureUpperBound}
              required
              {...form.register('temperatureUpperBound')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="temperatureLowerBound">
              <Text variant="heading3" className="text-sm">
                Temperature lower bound
              </Text>
            </label>
            <Input
              id="temperatureLowerBound"
              variant="contained"
              label="Temperature lower bound"
              type="number"
              defaultValue={rfidCarrier.config.temperatureLowerBound}
              required
              {...form.register('temperatureLowerBound')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="gpsDuration">
              <Text variant="heading3" className="text-sm">
                GPS duration
              </Text>
            </label>
            <Input
              id="gpsDuration"
              variant="contained"
              label="GPS duration"
              type="number"
              defaultValue={rfidCarrier.config.gpsDuration}
              required
              {...form.register('gpsDuration')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="logMask">
              <Text variant="heading3" className="text-sm">
                Log Mask
              </Text>
            </label>
            <Input
              id="logMask"
              variant="contained"
              label="Log Mask"
              type="number"
              defaultValue={rfidCarrier.config.logMask}
              required
              {...form.register('logMask')}
            />
          </Grid>

          {/* TODO RFID Carrier Comment | 16.10.2024 jlbrt */}
        </Grid>
      </div>

      <div className="flex justify-end gap-2.5 pt-8">
        <Button type="button" variant="outlined" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button type="submit" variant="contained">
          Speichern
        </Button>
      </div>
    </form>
  );
}
