import CloseIcon from '@mui/icons-material/Close';
import * as RadixDialog from '@radix-ui/react-dialog';
import * as RadixTabs from '@radix-ui/react-tabs';
import { Text } from '@ui/Text';
import { IconButton } from './IconButton';
import { SvgIconComponent } from '@mui/icons-material';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  Icon: SvgIconComponent;
  tabs: {
    value: string;
    label: string;
    Icon: SvgIconComponent;
  }[];
  activeTab: string;
  onTabChange: (newTab: string) => void;
  children?: React.ReactNode;
};

export function Dialog({ isOpen, onClose, title, Icon, tabs, activeTab, onTabChange, children }: Props) {
  return (
    <RadixDialog.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="fixed inset-0 z-20 bg-black/20" />

        <RadixTabs.Root value={activeTab} onValueChange={onTabChange}>
          <RadixDialog.Content
            className="fixed left-1/2 top-1/2 z-30 max-h-[96vh] w-[96vw] max-w-[650px] -translate-x-1/2 -translate-y-1/2 overflow-hidden overflow-y-auto rounded-md bg-white shadow-lg focus:outline-none"
            aria-describedby=""
          >
            <div className="flex flex-col gap-6 bg-gradient-to-r from-blue-600 to-[#C5E5C4] to-[211%] px-7 pt-4 shadow">
              <div className="flex items-center gap-6">
                <div className="p-2">
                  <Icon fontSize="large" className="text-white" />
                </div>

                <Text variant="heading2" className="grow text-white">
                  <RadixDialog.Title>{title}</RadixDialog.Title>
                </Text>

                <IconButton onClick={onClose} className="hover:bg-gray-950/5">
                  <CloseIcon className="text-white" />
                </IconButton>
              </div>

              <RadixTabs.List className="flex">
                {tabs.map((tab) => (
                  <RadixTabs.Trigger
                    key={tab.value}
                    value={tab.value}
                    className="flex w-36 items-center justify-center gap-2.5 border-b-2 border-transparent px-2.5 py-3 text-white/70 data-[state=active]:border-white data-[state=active]:text-white"
                  >
                    <tab.Icon />

                    <Text variant="overline">{tab.label}</Text>
                  </RadixTabs.Trigger>
                ))}
              </RadixTabs.List>
            </div>

            {children}
          </RadixDialog.Content>
        </RadixTabs.Root>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

type DialogTabProps = {
  value: string;
  children?: React.ReactNode;
};

Dialog.Tab = function DialogTab({ value, children }: DialogTabProps) {
  return (
    <RadixTabs.Content value={value} asChild>
      <div className="px-7 py-8">{children}</div>
    </RadixTabs.Content>
  );
};
