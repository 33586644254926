import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { createSvgIcon } from '@mui/material/utils';
import { Dialog } from '@ui/Dialog';
import { useState } from 'react';
import { SensorDetailsConfigTab } from './SensorDetailsConfigTab';
import { SensorDetailsLogsTab } from './SensorDetailsLogsTab';

const SLCapIcon = createSvgIcon(
  <path d="M16.28 12.95V13.68C16.28 14.06 15.98 14.36 15.6 14.37H15.47V14.94L14.9 15.55H14.38V21.35L13.81 21.93H10.21L9.63999 21.35V15.55H9.12999L8.55999 14.94V14.37H8.41999C8.03999 14.37 7.73998 14.06 7.73998 13.68V12.95C7.73998 12.57 8.03999 12.26 8.41999 12.26H15.61C15.99 12.26 16.29 12.57 16.29 12.95M13.61 6.97998L14.4 7.67998C15.58 6.68998 15.73 4.92998 14.74 3.75998C14.64 3.63998 14.52 3.52998 14.4 3.41998L13.61 4.11998C14.4 4.77998 14.5 5.95998 13.83 6.74998C13.76 6.82998 13.69 6.90998 13.61 6.96998M12.01 6.68998C12.68 6.72998 13.26 6.21998 13.3 5.54998C13.2 4.83998 12.54 4.34998 11.83 4.44998C11.26 4.52998 10.81 4.97998 10.73 5.54998C10.77 6.21998 11.35 6.72998 12.02 6.68998M16.01 1.98998L15.21 2.69998C16.04 3.40998 16.52 4.44998 16.53 5.53998C16.53 6.63998 16.04 7.66998 15.21 8.38998L16.01 9.09998C17.05 8.20998 17.65 6.90998 17.67 5.53998C17.66 4.16998 17.05 2.86998 16.01 1.97998M9.27998 7.32998C9.37998 7.44998 9.49999 7.56998 9.61999 7.66998L10.41 6.96998C9.61999 6.30998 9.51999 5.12998 10.18 4.33998C10.25 4.25998 10.32 4.17998 10.4 4.11998L9.60998 3.41998C8.42999 4.40998 8.27999 6.15998 9.26999 7.33998M8.80999 8.38998C7.97999 7.67998 7.49998 6.63998 7.48998 5.54998C7.49998 4.45998 7.97999 3.41998 8.80999 2.70998L8.00999 1.99998C6.96999 2.88998 6.36999 4.18998 6.35999 5.54998C6.36999 6.91998 6.97999 8.20998 8.00999 9.09998L8.80999 8.38998Z" />,
  'SLCapIcon'
);

type Props = {
  rfidCarrier: {
    id: string;
    serial: string;
    rfidTagUID: string;
    imei: string | null;
    config: {
      measurementsPerDay?: number;
      gpsInterval?: number;
      accumulateEvents?: number;
      temperatureUpperBound?: number;
      temperatureLowerBound?: number;
      gpsDuration?: number;
      logMask?: number;
    };
  };
  onClose: () => void;
};

export function SensorDetailsDialog({ rfidCarrier, onClose }: Props) {
  const [activeTab, setActiveTab] = useState<string>('config');

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      title="SL Cap Details"
      Icon={SLCapIcon}
      tabs={[
        {
          label: 'Config',
          Icon: BuildCircleOutlinedIcon,
          value: 'config',
        },
        {
          label: 'Logs',
          Icon: HistoryIcon,
          value: 'logs',
        },
      ]}
      activeTab={activeTab}
      onTabChange={setActiveTab}
    >
      <Dialog.Tab value="config">
        <SensorDetailsConfigTab rfidCarrier={rfidCarrier} onCancel={onClose} />
      </Dialog.Tab>

      <Dialog.Tab value="logs">
        <SensorDetailsLogsTab rfidCarrier={rfidCarrier} />
      </Dialog.Tab>
    </Dialog>
  );
}
