import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@ui/Button';
import { Text } from '@ui/Text';
import dayjs from 'dayjs';

type Props = {
  rfidCarrier: {
    id: string;
  };
};

export function SensorDetailsLogsTab({ rfidCarrier }: Props) {
  const api = useApi();

  const rfidCarrierLogsQuery = useQuery({
    queryKey: ['rfidCarrierLogs', rfidCarrier.id],
    queryFn: () => api.rfidCarriers.getRfidCarrierLogs({ id: rfidCarrier.id }),
  });

  async function refreshLogs() {
    await rfidCarrierLogsQuery.refetch();
  }

  const isLoading = rfidCarrierLogsQuery.isLoading;

  if (isLoading) {
    return 'Loading...';
  }

  const rfidCarrierLogs = rfidCarrierLogsQuery.data?.ok ? rfidCarrierLogsQuery.data.data.data : [];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Text variant="body1">Die neusten 100 Log-Einträge werden angezeigt.</Text>

        <Button variant="contained" onClick={refreshLogs} disabled={rfidCarrierLogsQuery.isFetching}>
          Aktualisieren
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        {rfidCarrierLogs.map((log) => {
          const createdAtString = dayjs(log.createdAt).format('L LTS');
          const relativeTimeString = dayjs(log.createdAt).fromNow();

          return (
            <div key={log.id} className="flex flex-col py-1">
              <div className="flex items-start justify-between">
                <div className="flex flex-col items-start">
                  <span className="text-sm">Firmware {log.fwVersion}</span>

                  <span className="text-xs">Config {dayjs(log.configTimestamp).format('L LTS')}</span>
                </div>

                <div className="flex flex-col items-end">
                  <span className="text-sm">{createdAtString}</span>

                  <span className="text-xs">{relativeTimeString}</span>
                </div>
              </div>

              <pre className="mt-1 bg-black px-1 py-1 font-mono text-sm font-medium text-white">{log.message}</pre>
            </div>
          );
        })}
      </div>
    </div>
  );

  return <pre>{JSON.stringify(rfidCarrierLogs, null, 2)}</pre>;
}
