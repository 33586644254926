import { useMapStyleStore } from '@stores/mapStyleStore';
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { Layer, Map, MapRef, Marker, Source } from 'react-map-gl';
import CONFIG from '../config';
import { Button } from '@ui/Button';
import { cn, sleep } from '../utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@hooks/useApi';
import { MarkerPin } from '@ui/MarkerPin';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AdjustIcon from '@mui/icons-material/Adjust';

const formSchema = z.object({
  imei: z.string().min(1),
  accuracy: z.coerce.number().int().positive(),
  batteryLevel: z.coerce.number().int().positive(),
  temperature: z.coerce.number(),
  shock: z.coerce.number().nonnegative(),
});

export function Faker() {
  const mapStyleStore = useMapStyleStore();
  const api = useApi();
  const queryClient = useQueryClient();

  const mapRef = useRef<MapRef>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: 13.715,
    latitude: 51.125,
    zoom: 12,
  });

  const form = useForm<z.infer<typeof formSchema>>({ resolver: zodResolver(formSchema) });

  const handleSubmit = form.handleSubmit(async (data) => {
    await fetch(`${CONFIG.API_URI}/v1/io/rfidCarrierSensorEvent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fwVersion: 'faker',
        configTimestamp: new Date(0),
        imei: data.imei,
        events: [
          {
            timestamp: new Date().getTime(),
            batteryLevel: data.batteryLevel,
            location: {
              lng: viewState.longitude,
              lat: viewState.latitude,
              accuracy: data.accuracy,
            },
            temperature: data.temperature || null,
            shock: data.shock || null,
          },
        ],
      }),
    });

    await sleep(2000);
    await queryClient.invalidateQueries(['containers']);
  });

  const zonesQuery = useQuery({
    queryKey: ['zones'],
    queryFn: api.zones.getAllZones,
  });
  const zones = zonesQuery.data?.data.data || [];

  const containersQuery = useQuery({
    queryKey: ['containers'],
    queryFn: api.containers.getAllContainers,
  });
  const containers = containersQuery.data?.data.data || [];

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.resize();
  }, [isInitialized]);

  function handleContainerClick(containerId: string) {
    const container = containers.find((c) => c.id === containerId);
    if (!container) return;
    const sensor = container.rfidCarriers.find((rc) => rc.type === 'sensor');
    if (!sensor) return;

    form.setValue('imei', sensor.imei || '');
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  const accuracyWatch = form.watch('accuracy');

  return (
    <div className="relative h-screen w-full">
      <div className="absolute bottom-5 left-5 z-50 h-12 rounded-sm bg-white shadow-md">
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full">
            {mapStyleStore.availableStyles.map((mapStyle) => {
              const isActive = mapStyle.target === mapStyleStore.selectedStyle;

              return (
                <Button
                  key={mapStyle.target}
                  variant="tabInactive"
                  onClick={() => mapStyleStore.setStyle(mapStyle.target)}
                  className={cn('h-12 text-onSurface-mediumEmphasis', isActive && 'text-onSurface-highEmphasis')}
                >
                  {mapStyle.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="absolute right-0 top-0 z-10 w-full max-w-lg p-4">
        <div className="w-full rounded bg-white p-6 opacity-60 shadow-xl transition-opacity hover:opacity-100">
          <h2 className="text-xl font-medium">Container Data</h2>

          <div className="mt-6">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label htmlFor="imei" className="font-medium">
                    IMEI
                  </label>
                  <input
                    id="imei"
                    type="text"
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                    {...form.register('imei')}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="longitude" className="font-medium">
                    Longitude
                  </label>
                  <input
                    id="longitude"
                    type="number"
                    readOnly
                    value={viewState.longitude}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="latitude" className="font-medium">
                    Latitude
                  </label>
                  <input
                    id="latitude"
                    type="number"
                    readOnly
                    value={viewState.latitude}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="accuracy" className="font-medium">
                    Accuracy (meters)
                  </label>
                  <input
                    id="accuracy"
                    type="number"
                    step={1}
                    min={0}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                    {...form.register('accuracy')}
                    defaultValue={8}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="batteryLevel" className="font-medium">
                    Battery Level
                  </label>
                  <input
                    id="batteryLevel"
                    type="number"
                    step={1}
                    min={0}
                    max={100}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                    {...form.register('batteryLevel')}
                    defaultValue={100}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="temperature" className="font-medium">
                    Temperature
                  </label>
                  <input
                    id="temperature"
                    type="number"
                    step={0.01}
                    min={0}
                    max={100}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                    {...form.register('temperature')}
                    defaultValue={22}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="shock" className="font-medium">
                    Schock
                  </label>
                  <input
                    id="shock"
                    type="number"
                    step={0.01}
                    min={0}
                    max={100}
                    className="w-full rounded border border-gray-400 px-2.5 py-1.5"
                    {...form.register('shock')}
                  />
                </div>

                <div className="self-end">
                  <button
                    type="submit"
                    className="rounded bg-blue-600 px-2.5 py-1.5 font-medium text-white transition-colors hover:bg-blue-700"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Map
        onLoad={() => setIsInitialized(true)}
        onRemove={() => setIsInitialized(false)}
        ref={mapRef}
        {...viewState}
        onMove={(e) => setViewState(e.viewState)}
        mapLib={mapboxgl}
        mapboxAccessToken={CONFIG.MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyleStore.selectedStyle}
      >
        {isInitialized && (
          <>
            {/* Crosshair Marker */}
            <Marker longitude={viewState.longitude} latitude={viewState.latitude}>
              <AdjustIcon className="text-red-500 opacity-80" sx={{ fontSize: 48 }} />
            </Marker>

            {/* Accuracy Circle */}
            <Source type="geojson" data={createGeoJSONCircle(viewState.longitude, viewState.latitude, accuracyWatch)}>
              <Layer type="fill" paint={{ 'fill-color': '#447086', 'fill-opacity': 0.5 }} />
            </Source>

            {/* Zones */}
            <Source
              type="geojson"
              data={{
                type: 'FeatureCollection',
                features: zones.map((zone) => ({
                  type: 'Feature',
                  properties: {},
                  geometry: zone.area,
                })),
              }}
            >
              <Layer type="fill" paint={{ 'fill-color': '#42B2CE', 'fill-opacity': 0.2 }} />
              <Layer type="line" paint={{ 'line-color': '#42B2CE', 'line-width': 1 }} />
            </Source>

            {/* Containers */}
            {containers
              .filter((c) => c.location !== null)
              .filter((c) => c.rfidCarriers.filter((rc) => rc.type === 'sensor').length > 0)
              .map((container) => (
                <Marker
                  key={container.id}
                  onClick={() => handleContainerClick(container.id)}
                  longitude={container.location!.lng}
                  latitude={container.location!.lat}
                >
                  <MarkerPin containerType={container.type} />
                </Marker>
              ))}
          </>
        )}
      </Map>
    </div>
  );
}

const createGeoJSONCircle = function (lng: number, lat: number, radiusInM: number) {
  const points = 64;

  var km = radiusInM / 1000;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos((lat * Math.PI) / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([lng + x, lat + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'Feature' as const,
    geometry: {
      type: 'Polygon' as const,
      coordinates: [ret],
    },
    properties: {},
  };
};
